import React, { useCallback } from "react";
import { Trans } from "@lingui/macro";
import { HeaderLink } from "./HeaderLink";
import "./Header.css";
import { METIS, getChainIcon, getChainName } from "config/chains";
import Web3Status from "components/Web3Status";

const NETWORK_OPTIONS = [
  {
    label: getChainName(METIS),
    value: METIS,
    icon: getChainIcon(METIS, 24),
    color: "#E841424D",
  },
];

type Props = {
  small?: boolean;
  clickCloseIcon?: () => void;
  openSettings?: () => void;
  redirectPopupTimestamp: number;
  showRedirectModal: (to: string) => void;
  setWalletModalVisible: (visible: boolean) => void;
  disconnectAccountAndCloseSettings: () => void;
};

export function AppHeaderLinks({
  small,
  openSettings,
  clickCloseIcon,
  redirectPopupTimestamp,
  showRedirectModal,
  setWalletModalVisible,
  disconnectAccountAndCloseSettings,
}: Props) {
  return (
    <div className="App-header-links">
      <div className="App-header-link-container">
        <HeaderLink to="/trade" redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal}>
          <Trans>Trade</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container">
        <HeaderLink to="/buy_tlp" redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal}>
          <Trans>Liquidity</Trans>
        </HeaderLink>
      </div>
      {/*<div className="App-header-link-container">
        <HeaderLink to="/earn" redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal}>
          <Trans>Earn</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container">
        <HeaderLink
          to="/referrals"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <Trans>Referrals</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container">
        <HeaderLink to="/buy" redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal}>
          <Trans>Buy</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container">
        <HeaderLink
          to="/dashboard"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <Trans>Dashboard</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container">
        <a href="https://stats.tethys.finance/" target="_blank" rel="noreferrer">
          <Trans>Analytics</Trans>
        </a>
      </div>
      {/*<div className="App-header-link-container">
        <HeaderLink to="/bridge" redirectPopupTimestamp={redirectPopupTimestamp} showRedirectModal={showRedirectModal}>
          <Trans>Bridge</Trans>
        </HeaderLink>
      </div>*/}
      {!small && (
        <Web3Status
          disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
          setWalletModalVisible={() => setWalletModalVisible(true)}
        />
      )}

      {/* {small && !isHomeSite() && (
        <div className="App-header-link-container">
          <a href="#" onClick={openSettings}>
            <Trans>Settings</Trans>
          </a>
        </div>
      )}
      <div className="App-header-link-inner">
        <a href="https://usd.tethys.finance/" target="_blank" rel="noreferrer">
          <Trans>
            <span>tUSD</span>
          </Trans>
        </a>
      </div>*/}
    </div>
  );
}
