import { MultiCallAbi3 } from "constants/abi/Multicall3";
import { ethers } from "ethers";
import { getContract } from "hooks/useContract";
import { ChainId } from "types";

const MULTICALL_ADDRESS = "0xb5DA8Ac7d1bf7A13178Fb5d65299a388c670BDBE";

export interface Call {
  address: string; // Address of the contract
  name: string; // Function name on the contract (example: balanceOf)
  params?: any[]; // Function params
}

export const getMulticallContract: any = (signer: any, chainId = ChainId.METIS) => {
  return getContract(MULTICALL_ADDRESS, MultiCallAbi3, signer);
};

export const multicallFailSafe = async <T = any>(
  abi: any[],
  calls: Call[],
  signer?: ethers.Signer | ethers.providers.Provider,
  chainId?: ChainId
): Promise<T> => {
  try {
    const multi = getMulticallContract(signer, chainId);
    const itf = new ethers.utils.Interface(abi);

    const calldata = calls.map((call) => [
      call.address.toLowerCase(),
      true,
      itf.encodeFunctionData(call.name, call.params),
    ]);
    const returnData = await multi.aggregate3(calldata, { blockTag: "pending" });

    const malformedResult: any[] = [];
    returnData.forEach((v) => {
      malformedResult.push(v[1]);
    });
    const res = malformedResult.map((call: any, i: any) => {
      if (call === "0x") {
        call =
          "0x000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000";
      }
      return itf.decodeFunctionResult(calls[i].name, call);
    });

    return res as any;
  } catch (error) {
    throw new Error(error as any);
  }
};
