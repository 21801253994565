import metisChainLogo from "../../logos/chains/metis.png";
import bscLogo from "../../logos/chains/bsc.png";
import ethereumLogo from "../../logos/chains/ethereum.png";
import fantomLogo from "../../logos/chains/fantom.png";
import avalancheLogo from "../../logos/chains/avalanche.png";
import arbitrumLogo from "../../logos/chains/arbitrum.svg";
import polygonLogo from "../../logos/chains/polygon.png";
import cronosLogo from "../../logos/chains/cronos.svg";
import optimismLogo from "../../logos/chains/optimism.png";

import { ANDROMEDA_TOKENS } from "../tokens/andromeda";
import { BridgeToken } from "../tokens/types";
import { ETHEREUM_TOKENS } from "../tokens/ethereum";
import { BSC_TOKENS } from "../tokens/bsc";
import { AVALANCHE_TOKENS } from "../tokens/avalanche";
import { FANTOM_TOKENS } from "../tokens/fantom";
import { ARBITRUM_TOKENS } from "../tokens/arbitrum";
import { POLYGON_TOKENS } from "../tokens/polygon";
import { CRONOS_TOKENS } from "../tokens/cronos";
import { OPTIMISM_TOKENS } from "../tokens/optimism";
import { StaticJsonRpcProvider } from "@ethersproject/providers";
import { ChainId } from "types";

export type BridgeChain = {
  id: number;
  name: string;
  logo: string;
  tokens: Array<BridgeToken>;
  gasToken: {
    address: string;
    symbol: string;
    decimals: number;
  };
};

export const ANDROMEDA_CHAIN = {
  id: ChainId.METIS,
  name: "Metis Andromeda",
  logo: metisChainLogo,
  tokens: ANDROMEDA_TOKENS,
  gasToken: {
    address: "0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000",
    symbol: "METIS",
    decimals: 18,
  },
};

export const ETHEREUM_CHAIN = {
  id: ChainId.ETHEREUM,
  name: "Ethereum",
  logo: ethereumLogo,
  tokens: ETHEREUM_TOKENS,
  gasToken: {
    address: "",
    symbol: "ETH",
    decimals: 18,
  },
};

export const FANTOM_CHAIN = {
  id: ChainId.FANTOM,
  name: "Fantom Opera",
  logo: fantomLogo,
  tokens: FANTOM_TOKENS,
  gasToken: {
    address: "",
    symbol: "FTM",
    decimals: 18,
  },
};

export const AVALANCHE_CHAIN = {
  id: ChainId.AVALANCHE,
  name: "Avalanche",
  logo: avalancheLogo,
  tokens: AVALANCHE_TOKENS,
  gasToken: {
    address: "",
    symbol: "AVAX",
    decimals: 18,
  },
};

export const BSC_CHAIN = {
  id: ChainId.BSC,
  name: "Binance Smart Chain",
  logo: bscLogo,
  tokens: BSC_TOKENS,
  gasToken: {
    address: "",
    symbol: "BNB",
    decimals: 18,
  },
};

export const POLYGON_CHAIN = {
  id: ChainId.POLYGON,
  name: "Polygon",
  logo: polygonLogo,
  tokens: POLYGON_TOKENS,
  gasToken: {
    address: "",
    symbol: "MATIC",
    decimals: 18,
  },
};

export const ARBITRUM_CHAIN = {
  id: ChainId.ARBITRUM,
  name: "Arbitrum",
  logo: arbitrumLogo,
  tokens: ARBITRUM_TOKENS,
  gasToken: {
    address: "",
    symbol: "ETH",
    decimals: 18,
  },
};

// export const CRONOS_CHAIN = {
//   id: ChainId.CRONOS,
//   name: 'Cronos',
//   logo: cronosLogo,
//   tokens: CRONOS_TOKENS,
//   gasToken: {
//     address: '',
//     symbol: 'CRO',
//     decimals: 18,
//   },
// }

export const OPTIMISM_CHAIN = {
  id: ChainId.OPTIMISM,
  name: "Optimism",
  logo: optimismLogo,
  tokens: OPTIMISM_TOKENS,
  gasToken: {
    address: "",
    symbol: "ETH",
    decimals: 18,
  },
};

export const CHAIN_LIST: Array<BridgeChain> = [
  ANDROMEDA_CHAIN,
  ETHEREUM_CHAIN,
  BSC_CHAIN,
  AVALANCHE_CHAIN,
  FANTOM_CHAIN,
  POLYGON_CHAIN,
  ARBITRUM_CHAIN,
  // CRONOS_CHAIN,
  OPTIMISM_CHAIN,
];

type NetworkConfig = {
  chainId: string;
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  rpcUrls: Array<string>;
  blockExplorerUrls: Array<string>;
};

export const SUPPORTED_BRIDGE_NETWORKS: Record<number, NetworkConfig> = {
  [ChainId.ETHEREUM]: {
    chainId: `0x${ChainId.ETHEREUM.toString(16)}`,
    chainName: "Ethereum",
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://mainnet.infura.io/v3"],
    blockExplorerUrls: ["https://etherscan.com/"],
  },
  [ChainId.METIS]: {
    chainId: `0x${ChainId.METIS.toString(16)}`,
    chainName: "Andromeda",
    nativeCurrency: {
      name: "Metis",
      symbol: "METIS",
      decimals: 18,
    },
    rpcUrls: ["https://sepolia.rpc.metisdevops.link"],
    blockExplorerUrls: ["https://sepolia.explorer.metisdevops.link/"],
  },
  [ChainId.AVALANCHE]: {
    chainId: `0x${ChainId.AVALANCHE.toString(16)}`,
    chainName: "Avalanche",
    nativeCurrency: {
      name: "Avalanche",
      symbol: "AVAX",
      decimals: 18,
    },
    rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
    blockExplorerUrls: ["https://snowtrace.io/"],
  },
  [ChainId.BSC]: {
    chainId: `0x${ChainId.BSC.toString(16)}`,
    chainName: "Binance Smart Chain",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed.binance.org", "https://bsc-dataseed1.defibit.io"],
    blockExplorerUrls: ["https://bscscan.com/"],
  },
  [ChainId.FANTOM]: {
    chainId: `0x${ChainId.FANTOM.toString(16)}`,
    chainName: "Fantom Opera",
    nativeCurrency: {
      name: "Fantom",
      symbol: "FTM",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.ftm.tools/"],
    blockExplorerUrls: ["https://ftmscan.com/"],
  },
  [ChainId.POLYGON]: {
    chainId: `0x${ChainId.POLYGON.toString(16)}`,
    chainName: "Polygon",
    nativeCurrency: {
      name: "Matic",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: [
      "https://rpc-mainnet.matic.quiknode.pro",
      "https://matic-mainnet.chainstacklabs.com",
      "https://rpc-mainnet.matic.network",
      "https://rpc-mainnet.maticvigil.com",
      "https://matic-mainnet-full-rpc.bwarelabs.com",
      "https://matic-mainnet-archive-rpc.bwarelabs.com",
    ],
    blockExplorerUrls: ["https://polygonscan.com/"],
  },
  [ChainId.ARBITRUM]: {
    chainId: `0x${ChainId.ARBITRUM.toString(16)}`,
    chainName: "Arbitrum",
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://arb1.arbitrum.io/rpc"],
    blockExplorerUrls: ["https://arbiscan.io"],
  },
  [ChainId.CRONOS]: {
    chainId: `0x${ChainId.CRONOS.toString(16)}`,
    chainName: "Cronos",
    nativeCurrency: {
      name: "CRO",
      symbol: "CRO",
      decimals: 18,
    },
    rpcUrls: ["https://evm.cronos.org", "https://evm-cronos.crypto.org"],
    blockExplorerUrls: ["https://cronoscan.com/"],
  },
  [ChainId.OPTIMISM]: {
    chainId: `0x${ChainId.OPTIMISM.toString(16)}`,
    chainName: "Optimism",
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://mainnet.optimism.io"],
    blockExplorerUrls: ["https://optimistic.etherscan.io/"],
  },
};

export const PROVIDERS_BY_CHAIN = {
  [ChainId.METIS]: new StaticJsonRpcProvider("https://sepolia.rpc.metisdevops.link", ChainId.METIS),
  [ChainId.ETHEREUM]: new StaticJsonRpcProvider(
    "https://rpc.ankr.com/eth",
    ChainId.ETHEREUM
  ),
  [ChainId.FANTOM]: new StaticJsonRpcProvider(
    "https://rpc.ankr.com/fantom",
    ChainId.FANTOM
  ),
  [ChainId.AVALANCHE]: new StaticJsonRpcProvider(
    "https://rpc.ankr.com/avalanche",
    ChainId.AVALANCHE
  ),
  [ChainId.BSC]: new StaticJsonRpcProvider(
    "https://rpc.ankr.com/bsc",
    ChainId.BSC
  ),
  [ChainId.ARBITRUM]: new StaticJsonRpcProvider(
    "https://rpc.ankr.com/arbitrum",
    ChainId.ARBITRUM
  ),
  [ChainId.POLYGON]: new StaticJsonRpcProvider(
    "https://rpc.ankr.com/polygon",
    ChainId.POLYGON
  ),
  [ChainId.CRONOS]: new StaticJsonRpcProvider("https://evm.cronos.org", ChainId.CRONOS),
  [ChainId.OPTIMISM]: new StaticJsonRpcProvider("https://mainnet.optimism.io", ChainId.OPTIMISM),
};

export const ReadOnlyProvider = new StaticJsonRpcProvider("https://sepolia.rpc.metisdevops.link", ChainId.METIS);
