import React from "react";
import cx from "classnames";
import { Trans } from "@lingui/macro";
import { ChainId } from "types";
import { useWeb3React } from "@web3-react/core";
import AddressDropdown from "components/AddressDropdown/AddressDropdown";
import { Box } from "components/Box";
import useSWR from "swr";
import MetisPunksRarityChecker from "abis/MetisPunksRarityChecker.json";
import ConnectWalletButton from "components/Common/ConnectWalletButton";
import { useChainId } from "lib/chains";
import { callContract, contractFetcher } from "lib/contracts";
import { getAccountUrl } from "lib/legacy";
import { setupNetwork } from "lib/wallets/setupNetwork";
import { setupBridgeNetwork } from "pages/Bridge/BridgeForm/hooks/setupBridgeNetwork";
import { useBridgeState } from "pages/Bridge/BridgeForm/hooks/useBridgeState";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { FiActivity } from "react-icons/fi";
import userIcon from "img/user-icon.svg";
import { ADDRESS_ZERO } from "@uniswap/v3-sdk";

const Web3StatusGeneric = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius};
  user-select: none;
`;
const Web3StatusError = styled(Web3StatusGeneric)`
  background-color: ${({ theme }) => theme.red1};
  border: 1px solid ${({ theme }) => theme.red1};
  height: 42px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 20px;
  border-radius: 30px;
  cursor: pointer;
  color: ${({ theme }) => theme.white};
  font-weight: 500;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0px 20px 40px rgb(0 0 0 / 14%), 0px 10.125px 17.4375px rgb(0 0 0 / 9%), 0px 4px 6.5px rgb(0 0 0 / 7%),
      0px 0.875px 2.3125px rgb(0 0 0 / 5%);
  }
  @media screen and (max-width: 1350px) {
    font-size: 13px;
    padding: 0 10px;
  }
  @media screen and (max-width: 1100px) {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 0;
    justify-content: center;
    align-items: center;
    & > div:last-child {
      display: none;
    }
  }
`;

const NetworkIcon = styled(FiActivity)`
  width: 16px;
  height: 16px;
`;

type Props = { disconnectAccountAndCloseSettings: () => void; setWalletModalVisible: (visible: boolean) => void };

export default function Web3Status({ disconnectAccountAndCloseSettings, setWalletModalVisible }: Props) {
  const { chainId } = useChainId();
  const { account, chainId: connectorChainId, library } = useWeb3React();

  const accountUrl = getAccountUrl(chainId, account);

  const bridgeState = useBridgeState();

  const location = useLocation();
  const isBridgePage = location.pathname.startsWith("/bridge");

  const isWrongBridgeNetwork = () => {
    if (isBridgePage) {
      return connectorChainId && bridgeState.chainFrom !== connectorChainId;
    }

    return false;
  };

  const isWrongAppNetwork = () => {
    if (!isBridgePage) {
      return connectorChainId !== ChainId.METIS;
    }

    return false;
  };

  if (account && library && isBridgePage && isWrongBridgeNetwork()) {
    return (
      <Web3StatusError
        onClick={() => {
          setupBridgeNetwork({
            library,
            account,
            chainId: bridgeState.chainFrom,
          });
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <NetworkIcon />
        </Box>
        <div>Wrong Network</div>
      </Web3StatusError>
    );
  } else if (account && library && !isBridgePage && isWrongAppNetwork()) {
    return (
      <Web3StatusError
        onClick={() => {
          setupNetwork();
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <NetworkIcon />
        </Box>
        <div>Wrong Network</div>
      </Web3StatusError>
    );
  } else if (account) {
    return (
      <div className="App-header-user-address">
        <AddressDropdown
          account={account}
          accountUrl={accountUrl}
          disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
          punkUrl={""}
        />
      </div>
    );
  } else {
    return (
      <ConnectWalletButton onClick={() => setWalletModalVisible(true)} imgSrc={userIcon}>
        <Trans>Connect wallet</Trans>
      </ConnectWalletButton>
    );
  }
}
