import { ChainId } from "types";
import { METIS_BRIDGE } from "../bridges/metis";
import { RELAY_BRIDGE } from "../bridges/relay";
import { SYNAPSE_BRIDGE } from "../bridges/synapse";
import { BridgeToken } from "./types";

export const ETHEREUM_TOKENS: Array<BridgeToken> = [
  {
    symbol: "METIS",
    logo: "https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/e274d85f-be43-4e2e-9618-6817a5dd0700/public",
    bridge: METIS_BRIDGE,
    addresses: {
      [ChainId.ETHEREUM]: "0x9E32b13ce7f2E80A01932B42553652E053D6ed8e",
      [ChainId.METIS]: "0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000",
    },
    decimals: {
      [ChainId.ETHEREUM]: 18,
      [ChainId.METIS]: 18,
    },
  },
  {
    symbol: "BTC",
    logo: "https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/7619eb33-a1ac-4a8c-86d2-c80ccb84d100/public",
    bridge: METIS_BRIDGE,
    addresses: {
      [ChainId.ETHEREUM]: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
      [ChainId.METIS]: "0x433E43047B95cB83517abd7c9978Bdf7005E9938",
    },
    decimals: {
      [ChainId.ETHEREUM]: 8,
      [ChainId.METIS]: 8,
    },
  },
  {
    symbol: "ETH",
    logo: "https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/b4ea7965-b4e1-4120-34c5-e9d21aa8a600/public",
    bridge: SYNAPSE_BRIDGE,
    addresses: {
      [ChainId.ETHEREUM]: "",
      [ChainId.METIS]: "0x420000000000000000000000000000000000000A",
    },
    decimals: {
      [ChainId.ETHEREUM]: 18,
      [ChainId.METIS]: 18,
    },
    isMainToken: true,
  },
  {
    symbol: "USDC",
    logo: "https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/b89c6d3d-eb81-498b-f480-8f6741c87300/public",
    bridge: SYNAPSE_BRIDGE,
    addresses: {
      [ChainId.ETHEREUM]: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
      [ChainId.METIS]: "0xEA32A96608495e54156Ae48931A7c20f0dcc1a21",
    },
    decimals: {
      [ChainId.ETHEREUM]: 6,
      [ChainId.METIS]: 6,
    },
  },
  {
    symbol: "USDT",
    logo: "https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/52913bcf-9597-4661-d045-102aff10e600/public",
    bridge: SYNAPSE_BRIDGE,
    addresses: {
      [ChainId.ETHEREUM]: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
      [ChainId.METIS]: "0xEA32A96608495e54156Ae48931A7c20f0dcc1a21",
    },
    decimals: {
      [ChainId.ETHEREUM]: 6,
      [ChainId.METIS]: 6,
    },
  },
  {
    symbol: "DAI",
    logo: "https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/0775bbfc-3d9e-4d9e-cecf-ef790b9e7400/public",
    bridge: METIS_BRIDGE,
    addresses: {
      [ChainId.ETHEREUM]: "0x6b175474e89094c44da98b954eedeac495271d0f",
      [ChainId.METIS]: "0x4c078361FC9BbB78DF910800A991C7c3DD2F6ce0",
    },
    decimals: {
      [ChainId.ETHEREUM]: 18,
      [ChainId.METIS]: 18,
    },
  },
  {
    symbol: "BUSD",
    logo: "https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/0600c8cd-6c9e-4644-0c4e-427a3992b300/public",
    bridge: METIS_BRIDGE,
    addresses: {
      [ChainId.ETHEREUM]: "0x4Fabb145d64652a948d72533023f6E7A623C7C53",
      [ChainId.METIS]: "0xb809cda0c2f79f43248C32b5DcB09d5cD26BbF10",
    },
    decimals: {
      [ChainId.ETHEREUM]: 18,
      [ChainId.METIS]: 18,
    },
  },
  {
    symbol: "AAVE",
    logo: "https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/2d36f8af-342e-4ad5-7c6d-33bd4796b100/public",
    bridge: METIS_BRIDGE,
    addresses: {
      [ChainId.ETHEREUM]: "0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9",
      [ChainId.METIS]: "0xd1F0A4E5444EEd0fbcd6624DCef7Ef33043E6168",
    },
    decimals: {
      [ChainId.ETHEREUM]: 18,
      [ChainId.METIS]: 18,
    },
  },
  {
    symbol: "LINK",
    logo: "https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/cb5c3cb0-a5ac-4f38-f9eb-ed13ead88600/public",
    bridge: METIS_BRIDGE,
    addresses: {
      [ChainId.ETHEREUM]: "0x514910771AF9Ca656af840dff83E8264EcF986CA",
      [ChainId.METIS]: "0xf5f66d5daa89c090a7afa10e6c1553b2887a9a33",
    },
    decimals: {
      [ChainId.ETHEREUM]: 18,
      [ChainId.METIS]: 18,
    },
  },
  {
    symbol: "SUSHI",
    logo: "https://imagedelivery.net/doDgMvXq0CcvAoj6k2stSg/a3e43789-ffbd-4df4-f4ef-40f596e40500/public",
    bridge: METIS_BRIDGE,
    addresses: {
      [ChainId.ETHEREUM]: "0x6B3595068778DD592e39A122f4f5a5cF09C90fE2",
      [ChainId.METIS]: "0x17ee7e4da37b01fc1bcc908fa63df343f23b4b7c",
    },
    decimals: {
      [ChainId.ETHEREUM]: 18,
      [ChainId.METIS]: 18,
    },
  }
];
