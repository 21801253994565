import { ethers } from "ethers";
import { METIS } from "./chains";

export const XGMT_EXCLUDED_ACCOUNTS = [
  "0x330eef6b9b1ea6edd620c825c9919dc8b611d5d5",
  "0xd9b1c23411adbb984b1c4be515fafc47a12898b2",
  "0xa633158288520807f91ccc98aa58e0ea43acb400",
  "0xffd0a93b4362052a336a7b22494f1b77018dd34b",
];

const CONTRACTS = {
  
  [METIS]: {
    // METIS
    Vault: "0x1929f25c24D187aA40496cf4A3C7DE10E3f95A6A",
    Router: "0x25aD88Ee5F1c45A84D33322911aC698ecF4d073E",
    VaultReader: "0x561f06168F794A7015633c8cF71337CCE24C0f1D",
    Reader: "0x9399153aF562e4Fa07Aa90F953adc41bE58FF5FE",
    GlpManager: "0x00d4076F294DBc0481A7cD0359BDAF60d61edD53",
    RewardRouter: "0xD73E2B0EFe66a6Ebe3E5CB54A4F8ecbb7BC675a5",
    GlpRewardRouter: "0xD73E2B0EFe66a6Ebe3E5CB54A4F8ecbb7BC675a5",
    RewardReader: "0xb9d056B9Af988f9391D25D68e3B368636d1DbE66",
    NATIVE_TOKEN: "0x351E2d86e2ac25ff56e19968eD81561F423492BF", //WMETIS
    GLP: "0x59f618bb76947B6D7881F39ea08EDEd947906e7B",
    GMX: "0xBeA6b5Be5dadECBdba81c76821f6E1042F38f96d",
    ES_GMX: "0xf12004Bf78BfcA58e030830D67f774688Cb5013F",
    BN_GMX: "0x76994252012D1328741F500CFf68BBe19c3Cc457",
    USDG: "0xdfb9Cd5a531aAa03Cd502D66623754524c2E1519",
    ES_GMX_IOU: "", // placeholder address
 
    StakedGmxTracker: "0x5b5Fe4BE8B46852E26F936184986ff4e4157FBCa",
    BonusGmxTracker: "0x02CF7837436F3d72D7aA6D2191875e8E61fe766d",
    FeeGmxTracker: "0x4aCfA1ec82d5DaeCe3a402Ea2c6Ce998524d85d1", 
    StakedGlpTracker: "0xBEf0719e61C0dFF2f67B149c7819Cd7586A8fb03",
    FeeGlpTracker: "0x3BbdF23718475921b89955F6EE04bbADFB83e9ec",

    StakingV1: "",

    StakedGmxDistributor: "0x720E0C393D6473db47040A5c92b7c53da2628E94",
    StakedGlpDistributor: "0x17d43B10F4Eee04fe8Ebeb864CC7E5cfF6449c4D",

    GmxVester: "0x97f182E9a9B98f08aA7c27d53c4f34bC8321b3Df",
    GlpVester: "0x25a4A6e2Fb01885A0d08bB2ebE32D716Ed85b345",

    OrderBook: "0x80ED27cF30C5A077E7B927714C1E5c2F142e0c52",
    OrderExecutor: "",
    OrderBookReader: "0xD078382f04055f782F2136b60463234284f74133",

    PositionRouter: "0x8A3d9BE01188AC6e729710091AfAe0b9bfF26Cd0",
    PositionManager: "0x7a8B688b32e3FD62511E4Dc09D41A91214C97726",

    TraderJoeGmxAvaxPool: "0xc9b290FF37fA53272e9D71A0B13a444010aF4497", //fake
    ReferralStorage: "0x5485532CFeFdF4aA71F67f2b77289A3EE7175448",
    ReferralReader: "0xCD89d2D32AcBD4bD751d4f120272F7A3309079A8",
  
    Multicall: "0xb5DA8Ac7d1bf7A13178Fb5d65299a388c670BDBE",
  },
};

export function getContract(chainId: number, name: string): string {
  if (!CONTRACTS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }

  if (!CONTRACTS[chainId][name]) {
    throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
  }

  return CONTRACTS[chainId][name];
}
